
$hamburger-padding-x           : 12px !default;
$hamburger-padding-y           : 12px !default;
$hamburger-layer-width         : 28px !default;
$hamburger-layer-height        : 2px !default;
$hamburger-layer-spacing       : 6px !default;
$hamburger-layer-border-radius : 9px !default;


$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;



.hamburger {
  padding: 0 $hamburger-padding-x*0.5;
  @screen md{
    padding: $hamburger-padding-y $hamburger-padding-x;
  }
  display: inline-block;
  cursor: pointer;

  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  // Normalize (<button>)
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:hover {
    @if $hamburger-hover-use-filter == true {
      filter: $hamburger-hover-filter;
    }
  }

  &.is-active {
    .hamburger-inner {
      transition-delay: 0.22s;
      background-color: transparent !important;

      &::before {
        top: 0;
        transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(45deg);
      }

      &::after {
        top: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                    transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translate3d(0, $hamburger-layer-spacing + $hamburger-layer-height, 0) rotate(-45deg);
      }
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      @apply bg-white;
    }
  }
}

.hamburger-box {

  width: $hamburger-layer-width *0.8;
  height: ($hamburger-layer-height * 3 + $hamburger-layer-spacing * 2) * 0.8;
  perspective: $hamburger-layer-width * 2 * 0.8;
  @screen sm{
      width: $hamburger-layer-width;
      height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
      perspective: $hamburger-layer-width * 2;
  }
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: $hamburger-layer-height / 2 * 0.8;
  @screen md{
    top: $hamburger-layer-height / 2;
  }
  transition: background-color 0s 0.13s linear;
  &,
  &::before,
  &::after {
    width: $hamburger-layer-width * 0.8;
    height: $hamburger-layer-height;
    @apply bg-white;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    @screen sm{
      width: $hamburger-layer-width;
    }
  }

  &::before,
  &::after {
    content: "";
    display: block;
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  &::before {
    top: $hamburger-layer-height*0.8 + $hamburger-layer-spacing*0.8;
    @screen sm{
      top: $hamburger-layer-height + $hamburger-layer-spacing;
    }
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &::after {
    top: calculateRem(13.56px);
    @screen sm{
      top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
    }
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
                transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
}
