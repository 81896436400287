@tailwind base;

@import 'common/mixins';

@tailwind components;

/** Config */
@import 'config/variables';
@import 'config/external';

/** Common */
@import 'common/global';

/** Components */
@import 'components';

/** Vendor */
@import 'config/external';


/** Partials */
@import 'partials/header';
@import 'partials/footer';

@tailwind utilities;
