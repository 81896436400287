/**
 * Global
 */

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: $font-primary;
  @apply text-black;
  max-width: 100vw;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply mb-2;
}

p {
  @apply mb-3;
  &:last-of-type{
    @apply mb-0;
  }
}
button {
  @include transition(all 0.35s $transitioneffect);
  outline: 0 !important;
  border: 0;
  &:focus{
    outline: 0 !important;
    border: 0;
  }
}
input {
  @include transition(all 0.35s $transitioneffect);
  outline: 0 !important;
  &:focus{
    outline: 0 !important;
  }
}
a {
  @apply no-underline;
  outline: 0 !important;
  @include transition(all 0.35s $transitioneffect);
}



.compat-object-fit{
  background-size: cover;
  background-position: center center;
  picture, img{
    visibility: hidden;
    @include opacity(0);
  }
}
