.brand-header{
    max-width: calculateRem(170px);
    @screen lg{
        max-width: calculateRem(270px);
    }

}

.submenu{
    @apply absolute mt-3 bg-white rounded-md pointer-events-none overflow-hidden;
    min-width: calculateRem(230px);
    left: 50%;
    @include transform(translateX(-50%) translateY(-20px));
    top: 100%;
    visibility: hidden;
    @include opacity(0);
    @include transition(all .25s);
    @screen lg{
        @apply mt-6;
    }
    &.open{
        @include opacity(1);
        visibility: visible;
        @include transform(translateX(-50%) translateY(0px));
        @apply pointer-events-auto;
    }
    .submenu-link{
        @apply px-4 font-light text-center text-sm py-2 block;
        @screen lg{
            @apply text-base py-3;
        }
        &:hover{
            background: #F5CC30;
        }
    }
    li{
        border-color: rgba(0,0,0,0.1);
    }
}


