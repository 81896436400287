.app-links{
    @apply flex flex-wrap justify-center -mx-3;
    li{
        @apply w-full px-3 my-3;
        max-width: calculateRem(200px);
        @screen lg{
            max-width: calculateRem(350px);
        }
        a{
            @apply block;
            img{
                @apply w-full;
            }
        }
    }
}

.content-text{
    @apply font-light leading-normal;
    strong{
        @apply font-semibold;
    }
    a{
        @apply font-semibold text-primary border-b border-transparent;
        &:hover{
            @apply text-darkblue border-gray-300;
        }
        &.content-link-white{
            @apply font-semibold text-white border-b border-white border-opacity-40;
            &:hover{
                @apply text-darkblue border-opacity-100 border-white;
            }
        }
    }


    ul, ol{
        list-style: none;
        margin: calculateRem(15px) 0;
        li{
            margin-left: calculateRem(25px);
            position: relative;
            &:before{
                @apply bg-yellow;
                content: '';
                position: absolute;
                top: calculateRem(8px);
                left: calculateRem(-20px);
                width: calculateRem(6px);
                height: calculateRem(6px);
                border-radius: 999rem;
                @screen lg{
                    top: calculateRem(10px);
                }
            }
        }
        ul, ol {
            list-style: auto;
            margin: calculateRem(5px) 0;
            li{
                margin-left: calculateRem(30px);
                &:before{
                    @apply bg-yellow;
                    content: '';
                    position: absolute;
                    top: calculateRem(10px);
                    left: calculateRem(-24px);
                    width: calculateRem(6px);
                    height: calculateRem(6px);
                }
            }
            ul, ol {
                li{
                    &:before{
                        @apply bg-yellow;
                        content: '';
                        position: absolute;
                        top: calculateRem(10px);
                        left: calculateRem(-24px);
                        width: calculateRem(4px);
                        height: calculateRem(4px);
                    }
                }
            }

        }
    }


}


.accordion{
    li{
        @apply shadow-lg bg-white rounded-lg px-3 py-2 mb-6;
        @screen lg{
            @apply px-5 py-3;
        }
        .toggle{
            @apply flex items-center justify-between;
            .text{
                @apply flex-1 text-darkblue font-semibold;
            }
            .open, .close{
                @apply w-4 h-4 flex items-center justify-end;
            }
            .close{
                @apply hidden;
            }
            .open{
                @apply flex;
            }
            &.open{
                .close{
                    @apply block;
                }
                .open{
                    @apply hidden;
                }
            }
        }
        &>div{
            @apply hidden pt-6 pb-3;
        }
    }
}

.numbered-list{
    margin: 20px auto 40px;
    list-style-type: none;
    counter-reset: section;
    padding: 0;
    li{
        position: relative;
        padding-left: 60px;
        @apply mb-6;
        &:before{
            counter-increment: section;
            content: counter(section);
            position: absolute;
            height: 40px;
            width: 40px;
            display: block;
            text-align: center;
            line-height: 28px;
            border: 6px solid;
            border-radius: 50%;
            left: 0;
            top: -5px;
            box-sizing: border-box;
            @apply text-yellow text-lg font-semibold border-yellow;
        }
    }
}

.two-col-css {

}

.two-col-css {
    column-count: 1;
    column-gap: 40px;
    break-inside: avoid;
    @screen lg{
        column-count: 2;
    }
}



.alpha-list {
    padding: 30px 0 0;
    @screen lg{
        padding: 40px 3% 0;
    }
}

.alpha-list li {
    list-style-type: none;
    padding-left: 60px;
    position: relative;
    margin-bottom: 15px;
    padding-top: 5px;
    font-size: 21px;
    line-height: 25px
}

.alpha-list li .letter {
    @apply bg-yellow font-semibold text-black;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    line-height: 37px;
    text-align: center;
}


.loz-list {
    padding: 0;
    list-style-type: none;
    @screen lg{
        padding: 30px 4% 10px;
    }
}

.loz-list li {
    position: relative;
    padding-left: 90px;
    margin-bottom: 30px
}

.loz-list li span {
    @apply bg-yellow text-black rounded-lg font-semibold;
    position: absolute;
    left: 0;
    top: -4px;
    display: inline-block;
    width: 73px;
    text-align: center;
    line-height: 35px;
}

.guides {
    margin: 50px auto;
    list-style: none;
    width: 100%;
    max-width: 700px;
    padding: 0;
    @apply font-semibold;
    @screen lg{
        @apply text-xl;
    }
    li{
        margin: 20px auto;
        padding: 0;
        a{
            @apply flex items-center;
        }
        span{
            @apply text-primary;
        }
    }
    img{
        max-width: 100%;
        height: auto;
        z-index: 50;
    }
    .inner{
        @apply text-darkblue bg-white w-full flex-1;
        padding: 1rem;
        border-top-right-radius: calculateRem(20px);
        border-bottom-right-radius: calculateRem(20px);
        position: relative;
        padding-left: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        &>div{
            position: relative;
            z-index: 1;
        }
        &:before{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            @apply bg-white;
            left: 0px;
            width: 75px;
            transform: translateX(-100%);
            z-index: 0;
        }
    }
}


.form-group{
    @apply mb-4;
    label{
        @apply text-sm font-light text-white mb-1 block;
    }
    .form-input{
        @apply bg-white w-full px-3 py-2 rounded-lg border-0 outline-none text-black cursor-pointer appearance-none;
        &:focus{
            @apply outline-none shadow;
        }
        @screen lg{
            @apply px-5 py-3;
        }
    }
}
.btn-submit{
    @apply bg-yellow w-full px-3 py-2 rounded-lg border-0 outline-none text-black cursor-pointer;
        @screen lg{
            @apply px-5 py-3;
        }
        &:hover{
            @apply bg-darkblue text-white;
        }

}
#RadCaptcha1_SpamProtectorPanel {
    @apply flex w-full mb-5;
    p{
        @apply w-full flex-1 flex flex-wrap;

        label{
            @apply w-full mt-1 mb-2;
        }
    }
    img{
        @apply rounded-l-lg;
    }
    .inputpad{
        @apply px-3 py-2 rounded-r-lg relative overflow-hidden;
        height: calculateRem(50px);
        width: 100%;
        @screen lg{
            @apply px-5 py-3;
        }
    }
}

.menu-mobile{
    li{
        &.active{
            a{
                @apply bg-yellow text-black rounded-lg;
            }
        }
    }
}

.menu-mobile{
    &.active{
        @apply block;
        @screen lg{
            @apply flex;
        }
    }
}
