/**
 * Variables
 */


// FONTS

$font-primary: "proxima-nova", sans-serif;


// TRANSITIONS

$transitioneffect: cubic-bezier(.33333,0,.66667,.33333) !default;

